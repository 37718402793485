.loader-wrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2000;
}


.loader {
    height: 2px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
}
.loader:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 2px;
    background-color: #2980b9;
    animation: loading 2s linear infinite;
}

@keyframes loading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}

body.dragOver .file-drop {
    display:block;
}
body .file-drop {
    z-index:1000;
    height: 100px;
    width: 100px;
    text-align: center;
    border: dashed 2px gray;
    padding-top: 5px;
    cursor: pointer;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.navbar .header-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
}

.navbar .header-menu ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
}

.navbar .header-menu ul li a{
    padding: 5px 20px;
    margin: 0 10px;
    color: #fff;
}

.navbar .header-menu ul li a:hover{
    background: rgba(0,0,0,.1);
}

.navbar .dropdown-menu {
    left: unset;
    right: 0;
}

.attachment-item {
    padding: 5px 0 5px 5px;
    float: left;
    position: relative;
    max-height: 105px;
    overflow: hidden;
    width: 105px;
}
.attachment-item>.controls {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.0;
    background: rgba(0,0,0, 0.5);
    width: 100px;
}
.attachment-item>.controls>div {
    height: 100%;
    text-align: center;
    padding-top: 30px;
}
.attachment-item>.controls:hover {
    opacity: 1.0;
}

.table-clickable tbody tr {
    cursor: pointer;
}

.pagination {
    margin: 0px 10px;
}

.pagination>li>a {
    padding: 4px 10px;
}

.dotted {
    overflow: hidden;
    width:100%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    cursor: pointer;
    color: #0f74a8;
}

.datepicker {
    z-index: 9999 !important;
}

.user-in-position {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 0px 5px;
    display: inline-table;
    background-color: #f7f3f3;
    border-radius: 3px;
    border: 1px solid #ececec;
    cursor: pointer;
}

.user-in-position:hover {
    background-color: white;
}

.content-header>.tools {
    float: right;
    background: transparent;
    margin-top: 0;
    margin-bottom: 0;
    padding: 7px 5px;
    position: absolute;
    top: 5px;
    right: 10px;
}

.swal2-popup {
    font-size: 1.6rem !important;
}

.filter-control {
    margin-bottom: 0;
    position: relative;
    margin-left: 15px;
    height: 30px;
    width: 200px;
}

.ace_editor { height: 300px; }
.objects .item {
    padding: 2px 7px;
    background-color: rgba(0, 166, 90, 0.8);
    display: inline-block;
    color: white;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
}

.objects .item:hover {
    background-color: #00a65a;
}

.select2-selection--single {
    padding: 3px !important;
}

.filter-box {
    position: absolute;
    background: #ffffff;
    width: 100%;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.1);
}

.filter-box-header {
    cursor: pointer;
    padding: 4px;
}

.filter-box-body {
    border-top: 1px solid rgba(0,0,0,0.1);
}

.select-item {
    padding: 4px;
    cursor: pointer;
}

.select-item-selected {
    background: #ddd;
}
a {
    color: #333;
}
a:hover {
    color: #333;
}


.desc {
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    white-space: pre-line;
}